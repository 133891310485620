import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/simple-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "/lesson-plans/"
      }}>{`»`}{` Back to Lesson Plans`}</a></p>
    <p>{`Dear Students,`}</p>
    <p><strong parentName="p"><em parentName="strong">{`[AAA---Short debrief or grading status]`}</em></strong></p>
    <p>{`This week you have three things to complete:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Midterm exam.`}</strong>{` As mentioned earlier this is a short-answer exam that requires you to explain key concepts often along with examples. Review each lesson's main ideas, try to explain them out loud to a friend and you should be good to go! Part of our objective with exams is to help prepare you for job interviews and for meeting with users as both of these will require you to be familiar with terms and examples to demonstrate what you know or to help others learn.`}</li>
      <li parentName="ul"><strong parentName="li">{`Project 1 Final`}</strong>{` Be sure to review the instructions for this important final submission. We're looking forward to seeing your work collected into your process book and your final concepts on InVision!`}</li>
      <li parentName="ul"><strong parentName="li">{`Project 2 Part A`}</strong>{` We want to hit the ground running after this week with the next project. Hopefully by now you have some ideas about what you could do for this.`}</li>
    </ul>
    <p>{`I hope all goes well for you this week!`}</p>
    <p>{`Best,
`}<strong parentName="p"><em parentName="strong">{`[BBB---Prof name]`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      